.footer_cus {
  color: #fff;
  padding: .36rem 3.3rem .51rem;
  background: rgba(200, 158, 255, 0.05);
  box-sizing: border-box;
  pointer-events: auto;
  width: 100%;
  margin-top: 1rem;
  position: relative;
}

.footer_cus .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.footer_cus .top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer_cus .top .footer_logo {
  margin-right: .24rem;
}

.footer_cus .top .footer_logo img {
  height: .91rem;
}

.footer_cus .top .footer_info {
  font-family: ETEB;
  font-size: .16rem;
  font-weight: bold;
  line-height: .2rem;
  text-transform: uppercase;
  color: #FAFAFA;
}

.footer_cus .bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-top: .24rem;
}

.footer_cus .bottom .left .list {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 36px;
}

.footer_cus .bottom .left .list .footer_link {
  color: #585858;
  text-decoration: none;
  font-family: ETEB;
  font-size: .12rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #959595;
  transition: text-decoration linear .3s;
}

.footer_cus .bottom .left .list .footer_link:hover {
  text-decoration: underline;
  text-underline-offset: .5em;
}

.footer_cus .bottom .left .list .footer_link u {
  text-decoration: none;
}

.footer_cus .bottom .left .list .copy_right {
  font-family: ETEB;
  font-size: .12rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #959595;
}

.footer_cus .bottom .left .list .copy_right:hover {
  text-decoration: none;
}

.footer_cus .bottom .right .social_group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -0.12rem;
}

.footer_cus .bottom .right .social_group .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: .12rem;
  font-weight: normal;
  text-transform: capitalize;
  letter-spacing: 0em;
  text-decoration: none;
  color: #CCCCCE;
  margin: 0 .12rem;
  transition: all linear .5s;
}

.footer_cus .bottom .right .social_group .item img {
  height: .3rem;
  margin-right: .04rem;
}

.footer_cus .bottom .right .social_group .item:hover {
  opacity: .5;
}

@media (min-width: 1024px) and (max-width: 1450px) {
  .footer_cus {
    padding: .66rem 2rem .72rem;
  }
  .footer_cus .left .footer_info {
    max-width: 500px;
  }
}

@media screen and (max-width: 1024px) {
  .footer_cus {
    padding: .66rem .6rem .72rem;
  }
}
