iframe {
  display: none;
}

*{
  padding: 0;
  margin: 0;
}

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  margin: 0;
  background: #080B19;
}

html{
  --status-bar-height: 0px; 
  --top-window-height: 0px; 
  --window-left: 0px;
   --window-right: 0px; 
   --window-margin: 0px; 
   --window-top: calc(var(--top-window-height)
  + 0px); 
  --window-bottom: calc(50px + env(safe-area-inset-bottom));
}

@font-face {
  font-family: 'ETEB'; 
  src: url('./fonts/EuroTechnicExtBold.otf') format('opentype');
}

@font-face {
  font-family: 'ARR'; 
  src: url('./fonts/Anton-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'GBB'; 
  src: url('./fonts/Gemsbuck01Black-axn2g.ttf') format('opentype');
}