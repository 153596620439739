.header {
  padding: 0 3.4rem;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .logo {
  display: flex;
  align-items: center;
}

.header .logo img {
  height: .91rem;
}

.header .nav {
  display: flex;
  align-items: center;
}

.header .nav .main {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 -0.27rem;
}

.header .nav .main li {
  display: flex;
}

.header .nav .main li .button-main {
  padding: 0 0.27rem;
  font-size: .16rem;
  cursor: pointer;
  line-height: 2;
  display: block;
  position: relative;
  height: 100%;
  position: relative;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  transition: all linear .5s;
  font-family: ETEB;
}

.header .nav .main li .button-main.active {
  color: #84FFD0;
}

.header .nav .main li .button-main:hover span:before {
  transform: scaleX(1);
}

.header .nav .install {
  background: #FFFFFF;
  border: none;
  border-radius: .6rem;
  padding: .12rem .38rem;
  margin-left: .4rem;
  font-weight: 500;
  font-size: .16rem;
  line-height: 1;
  color: rgba(0, 0, 0, 0.9);
  transition: all linear .8s;
}

.header .nav .install:hover {
  opacity: 0.5;
}
