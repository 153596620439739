



.page {
  background: url(../../assets/home-bg.png) no-repeat top center;
  background-size: cover;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.page .home {
  width: 100%;
}

.page .home .banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.27rem;
  padding-top: 1.87rem;
  padding-bottom: .27rem;
}

.page .home .banner .title {
  margin-bottom: 2.08rem;
}

.page .home .banner .title img {
  height: 3.8rem;
}

.page .home .banner .about {
  padding: 0 3.73rem;
  font-family: ETEB;
  font-size: .14rem;
  font-weight: bold;
  line-height: .34rem;
  text-transform: uppercase;
  color: #FFFFFF;
}

.page .home .banner .about h3 {
  font-family: ARR;
  font-size: .3rem;
  font-weight: normal;
  color: #FFFFFF;
  margin-bottom: .26rem;
}

.page .home .airdrop {
  color: #FFFFFF;
  padding: 1rem;
}

.page .home .airdrop .contain {
  padding: 0 2.29rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page .home .airdrop .contain .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page .home .airdrop .contain .text h2 {
  font-family: ARR;
  text-align: center;
  /* $ */
  /* JIT Airdrop */
  font-weight: 400;
  font-size: .8rem;
}

.page .home .airdrop .contain .text h2 span {
  font-size: .86rem;
}

.page .home .airdrop .contain .text p {
  font-family: ETEB;
  font-size: .22rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.page .home .airdrop .contain .text .progress-box {
  margin-top: .58rem;
  width: 100%;
}

.page .home .airdrop .contain .text .progress-box .progress {
  max-width: 3.48rem;
  width: 100%;
  height: .11rem;
  border-radius: .6rem;
  opacity: 1;
  background: #423572;
  margin: auto;
}

.page .home .airdrop .contain .text .progress-box .progress .count {
  width: 20%;
  border-radius: 60px 0px 0px 60px;
  height: 100%;
  background: #00FFFF;
}

.page .home .airdrop .contain .text .progress-box .num {
  font-family: GBB;
  font-size: .2rem;
  font-weight: normal;
  text-align: center;
  margin-top: .1rem;
}

.page .home .airdrop .contain .text .claim {
  max-width: 3.1rem;
  width: 100%;
  border-radius: .2rem;
  margin-top: 1.2rem;
  background: #00FFFF;
  font-family: ETEB;
  font-size: .2rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  border: none;
  padding: .24rem 0;
}

.page .home .airdrop .contain .pic img {
  width: 7.69rem;
}

.page .home .coupon {
  margin-top: 1rem;
  position: relative;
  margin-bottom: 1.1rem;
}

.page .home .coupon .hotAir {
  position: absolute;
  left: 0;
  top: 0;
}

.page .home .coupon .hotAir img {
  width: 6.4886rem;
}

.page .home .coupon .list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: .83rem;
}

.page .home .coupon .list .item {
  color: #000000;
  max-width: 7.51rem;
  width: 100%;
  margin: .18rem 0;
  margin-bottom: .23rem;
  position: relative;
}

.page .home .coupon .list .item .content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .22rem .54rem .22rem .43rem;
  background: url(../../assets/list_bg.png) no-repeat center;
  background-size: 100% 100%;
  z-index: 2;
}

.page .home .coupon .list .item .content .num {
  font-family: GBB;
  font-size: .4rem;
  font-weight: normal;
  text-align: center;
  margin-right: .17rem;
}

.page .home .coupon .list .item .content .text {
  font-family: ETEB;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.page .home .coupon .list .item::after {
  content: '';
  position: absolute;
  left: .3rem;
  top: -.13rem;
  width: 100%;
  height: 100%;
  background: url(../../assets/list_bg_after.png) no-repeat center;
  background-size: 100% 100%;
}

.page .home .coupon .list .item:nth-child(2n) {
  margin-left: -2rem;
}

.page .home .coupon .block {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.45rem;
  position: relative;
}

.page .home .coupon .block>div {
  background: linear-gradient(270deg, #0E7C4E -13%, #080B19 76%);
  box-sizing: border-box;
  border: .04rem solid #0FFFFF;
  border-top: none;
  max-width: 10.43rem;
  width: 100%;
  position: relative;
}

.page .home .coupon .block>div .title {
  position: relative;
}

.page .home .coupon .block>div .title .title-text {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 45%, #FFFFFF 67%, #6C6C6C 87%, #D1D1D1 98%);
  position: relative;
  padding: .11rem 0 .05rem .11rem;
  font-family: GBB;
  font-size: .26rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: #000000;
}

.page .home .coupon .block>div .title .bg {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: #0FFFFF;
}

.page .home .coupon .block>div .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 .84rem .2rem .39rem;
}

.page .home .coupon .block>div .content .subtitle {
  font-family: ARR;
  font-size: .56rem;
  font-weight: normal;
  text-align: center;
  color: #FFFFFF;
  position: relative;
  transform: rotate(-15.32deg);
  margin-bottom: .4rem;
  margin-top: .2rem;
}

.page .home .coupon .block>div .content .subtitle span {
  position: relative;
  z-index: 2;
}

.page .home .coupon .block>div .content .subtitle::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: .05rem;
  background: #00C4C4;
  height: .37rem;
  width: 120%;
  transform: translateX(-50%);
}

.page .home .coupon .block>div .content p {
  font-family: ETEB;
  font-size: .14rem;
  font-weight: bold;
  line-height: .24rem;
  text-transform: uppercase;
  color: #FFFFFF;
}

.page .home .coupon .block>div .star {
  position: absolute;
  width: 1.26rem;
  right: .98rem;
  bottom: 1.14rem;
}

.page .home .coupon .block>div.mission {
  margin-left: -3.10rem;
}

.page .home .coupon .block>div.vision {
  background: linear-gradient(99deg, #080B19 5%, #6A0E7C 76%);
  box-sizing: border-box;
  border: 4px solid #7D10FA;
  border-top: none;
  margin-top: .41rem;
  margin-left: 3.10rem;
}

.page .home .coupon .block>div.vision .title .bg {
  background: #7D10FA;
}

.page .home .coupon .block>div.vision .content .subtitle::after {
  background: #7D10FA;
}

.page .home .coupon .block>div.vision .star {
  right: .21rem;
  bottom: 1.07rem;
}

.page .home .coupon .block .bgstar {
  position: absolute;
  left: 2.74rem;
  bottom: 0;
  width: 1.9rem;
  border: none;
  background: none;
}

.page .home .JustIntel {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(../../assets/product-bg.png) no-repeat top center;
  background-size: contain;
  background-position: 0 2.43rem;
  padding-top: 1rem;
}

.page .home .JustIntel .blockTile {
  margin-top: 0;
  margin-bottom: .24rem;
}

.page .home .JustIntel .subtext {
  font-family: ETEB;
  font-size: .24rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  max-width: 7.8rem;
  color: #FFFFFF;
}

.page .home .JustIntel .productlist {
  margin-top: 4.08rem;
  padding: 0 2.96rem 0 3.39rem;
}

.page .home .JustIntel .productlist .top {
  display: flex;
  align-items: flex-end;
  margin: 0 -.35rem;
}

.page .home .JustIntel .productlist .top>div {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 .35rem;
}

.page .home .JustIntel .productlist .top>div .img {
  width: 2.0121rem;
  display: flex;
}

.page .home .JustIntel .productlist .top>div .text {
  flex: 1;
  font-family: ETEB;
  padding-right: .4rem;
}

.page .home .JustIntel .productlist .top>div .text .title {
  font-size: .22rem;
  font-weight: bold;
  color: #84FFD0;
  margin-bottom: .78rem;
}

.page .home .JustIntel .productlist .top>div .text .subtitle {
  font-size: .14rem;
  font-weight: bold;
  line-height: .18rem;
  color: #FFFFFF;
  margin-bottom: .08rem;
}

.page .home .JustIntel .productlist .top>div .text p {
  font-size: .14rem;
  font-weight: bold;
  line-height: .18rem;
  color: #8C8C8C;
  margin-bottom: .28rem;
}

.page .home .JustIntel .productlist .top>div .text p:last-child {
  margin-bottom: 0;
}

.page .home .JustIntel .productlist .top>div.right {
  padding-bottom: .78rem;
}

.page .home .JustIntel .productlist .top>div.right .img {
  width: 2.4538rem;
}

.page .home .JustIntel .productlist .top>div.right .text {
  padding: 0;
  padding-left: .4rem;
}

.page .home .JustIntel .productlist .center {
  padding: 0 2.12rem;
}

.page .home .JustIntel .productlist .center .list {
  display: flex;
  justify-content: space-between;
}

.page .home .JustIntel .productlist .center .list .item {
  width: 2.01rem;
}

.page .home .JustIntel .productlist .center .list .item.item1 {
  margin-top: 1.88rem;
}

.page .home .JustIntel .productlist .center .list .item.item2 {
  margin-top: .94rem;
}

.page .home .JustIntel .productlist .bottom {
  padding: 0 2.12rem;
  display: flex;
  justify-content: flex-end;
}

.page .home .JustIntel .productlist .bottom .text {
  width: 100%;
  max-width: 5.5rem;
  text-align: center;
  font-family: ETEB;
}

.page .home .JustIntel .productlist .bottom .text .title {
  margin-bottom: .24rem;
  font-size: .22rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #FFFFFF;
}

.page .home .JustIntel .productlist .bottom .text p {
  padding: 0 .25rem;
  font-size: .14rem;
  font-weight: bold;
  line-height: .18rem;
  color: #8C8C8C;
}

.page .home .JustIntel .digital {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.56rem 3.75rem 1.82rem;
}

.page .home .JustIntel .digital .text {
  flex: 1;
  font-family: ETEB;
  font-size: .24rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #FFFFFF;
}

.page .home .JustIntel .digital .img {
  width: 5.06rem;
}

.page .home .kol {
  padding-left: 2.26rem;
  padding-bottom: 1.43rem;
  position: relative;
}

.page .home .kol .quan {
  position: absolute;
  top: 0;
  left: -1.82rem;
  width: 6.81rem;
  height: 3.3rem;
  opacity: 1;
  background: rgba(27, 253, 144, 0.3);
  filter: blur(300px);
}

.page .home .kol .img {
  width: 100%;
  max-width: 14.18rem;
}

.page .home .tokenomics {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(../../assets/tokenomics-bg.png) no-repeat top center;
  background-size: 100%;
  padding: 0 3.0rem;
  padding-top: 1.8rem;
}

.page .home .tokenomics .jit {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: url(../../assets/tokenomics-c-bg.png) no-repeat top center;
  background-size: 100% 100%;
  width: 100%;
  padding: 2.4rem 0 1.9rem;
}

.page .home .tokenomics .jit .title {
  font-family: ARR;
  font-size: .8rem;
  font-weight: normal;
  text-align: center;
  color: #FFFFFF;
}

.page .home .tokenomics .jit .des {
  position: absolute;
  left: .58rem;
  top: .59rem;
  width: 2.95rem;
  padding: .24rem .13rem;
  font-family: ETEB;
  font-size: .12rem;
  font-weight: bold;
  line-height: .22rem;
  text-transform: uppercase;
  letter-spacing: 0em;
  background: url(../../assets/token-text-bg.png) no-repeat top center;
  background-size: 100% 100%;
  color: #FFFFFF;
}

.page .home .tokenomics .chat {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2.4rem 0 1.9rem;
}

.page .home .tokenomics .chat .left {
  flex: 1;
}

.page .home .tokenomics .chat .left img {
  width: 6.9302rem;
}

.page .home .tokenomics .chat .right {
  padding-left: 1.17rem;
}

.page .home .tokenomics .chat .right .text {
  background: url(../../assets/chat-text-bg.png) no-repeat top center;
  background-size: 100%;
  font-family: ETEB;
  font-size: .12rem;
  font-weight: bold;
  line-height: .2rem;
  text-transform: uppercase;
  letter-spacing: 0em;
  width: 4.11rem;
  color: #FFFFFF;
  padding: .41rem .19rem .1rem .44rem;
}

.page .home .roadmap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 2.85rem;
  padding-right: 3.65rem;
}

.page .home .roadmap .list {
  flex-direction: column;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.07rem;
  background-size: 100%;
  position: relative;
}

.page .home .roadmap .list .item {
  width: 7.78rem;
  font-family: ETEB;
  background: url(../../assets/map-bg.png) no-repeat top center;
  background-size: 100% 100%;
  padding: .31rem .18rem .4rem .28rem;
  margin-bottom: .25rem;
}

.page .home .roadmap .list .item h4 {
  font-size: .22rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: .13rem;
}

.page .home .roadmap .list .item p {
  font-size: .12rem;
  font-weight: bold;
  line-height: .18rem;
  text-transform: lowercase;
  color: #2E2E2E;
}

.page .home .roadmap .list .item.item2 {
  margin-right: 1.3rem;
}

.page .home .roadmap .list .item.item3 {
  margin-right: 2.4rem;
}

.page .home .roadmap .list .item.item4 {
  margin-right: 3.7rem;
}

.page .home .roadmap .list::after {
  content: '';
  position: absolute;
  left: 15%;
  top: 0;
  width: .2197rem;
  height: 96%;
  transform: rotate(27.31deg);
  opacity: 1;
  background: url(../../assets/map-point.png) no-repeat top center;
  background-size: 100% 100%;
}

.page .home .ecosystem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3.64rem 0 3.38rem;
  margin-top: 2.38rem;
  margin-bottom: 1.48rem;
}

.page .home .ecosystem .left {
  flex: 1;
}

.page .home .ecosystem .left .blockTile {
  text-align: left;
}

.page .home .ecosystem .left p {
  font-family: ETEB;
  font-size: .16rem;
  font-weight: bold;
  line-height: .28rem;
  text-transform: uppercase;
  color: #FFFFFF;
}

.page .home .ecosystem .right img {
  width: 5.02rem;
}

.page .home .blockTile {
  font-family: ARR;
  font-size: .8rem;
  font-weight: normal;
  text-align: center;
  color: #FFFFFF;
}

.page .home .footer {
  width: 100%;
  box-sizing: border-box;
}

.page .bottom-quan {
  width: 9.82rem;
  height: 5.07rem;
  opacity: 1;
  background: #896CC2;
  filter: blur(500px);
  position: absolute;
  right: -6.22rem;
  bottom: -2.86rem;
}
